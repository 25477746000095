function initCatalogOrderForm(){
    updateStateOfCatalogTypeFields(false);

    $(".catalog-order-form FORM").on("submit", function(e){
        e.preventDefault();
        var submitBtn = $("input[type=submit]", this);

        submitBtn.addClass("loading");

        var form = $(".catalog-order-form FORM");
        var lightbox = $(".mfp-content");


        $.ajax( {
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: function( response ){
                $.magnificPopup.open({
                    items: {
                        src: response,
                        type: "inline"
                    }
                });
                initCatalogOrderForm();
            }
        });


    });
}

function updateStateOfCatalogTypeFields(animate){
    if(typeof animation == undefined){animate=true;}

    var catalogOrderForm =  $(".catalog-order-form FORM");
    var catalogTypeElectronicCheckbox = $("#catalogTypeElectronic", catalogOrderForm);
    var catalogTypePrintedCheckbox = $("#catalogTypePrinted", catalogOrderForm);

    if(catalogTypePrintedCheckbox.is(":checked")){
        if(animate){
            $(".addressdg", catalogOrderForm).slideDown("fast");
        }else{
            $(".addressdg", catalogOrderForm).show();
        }
    }else{
        if(animate){
            $(".addressdg", catalogOrderForm).slideUp("fast");
        }else{
            $(".addressdg", catalogOrderForm).hide();
        }
        catalogTypeElectronicCheckbox.prop("checked", true);
    }
}

$(function(){
    /*
     **
     ** Catalog order form
     **
     */

    updateStateOfCatalogTypeFields(false);

    $(document).on("change", ".catalog-order-form #catalogTypeElectronic, .catalog-order-form #catalogTypePrinted", updateStateOfCatalogTypeFields);

});

function initCatalogDownload(){
    var downloadUrl;

    $.get("/katalog/geturl", function(data){
        downloadUrl = data;
    });

    $(".cdc-countdown").countdown(downloadCatalog, 5);

    function downloadCatalog(){
        //console.log("download");
        window.location = downloadUrl;
    }
}

$.fn.countdown = function (callback, duration, message) {
    message = message || "";
    var container = $(this[0]).html(duration + message);
    var countdown = setInterval(function () {
        if (--duration) {
            container.html(duration + message);
        } else {
            container.html(duration + message);
            clearInterval(countdown);
            callback.call(container);
        }
        // Run interval every 1000ms (1 second)
    }, 1000);

};