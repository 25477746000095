$(function(){

    var form = $('.registracija-form');
    var podjetjeFieldSet = $('.registracija-form__company-info');
    var imepriimekLabel = $('#form_ime_priimek_cont .label label',form);
    var is_podjetje = $('.js-is-company-toggle', podjetjeFieldSet);


    is_podjetje.change(function(e){
        togglePodjetjeFields();
    });
    function togglePodjetjeFields(){
        var checked = is_podjetje.is(':checked');
        if (checked) {
            $('.formRow', podjetjeFieldSet).show();
            imepriimekLabel.data('prevhtml', imepriimekLabel.html());
            imepriimekLabel.html(translations.kontaktna_oseba);
            $('#form_kontaktna_st_cont').before($('#form_ime_priimek_cont'));
        } else {
            $('.formRow', podjetjeFieldSet).not(':first').hide();
            if (imepriimekLabel.data('prevhtml')) {
                imepriimekLabel.html(imepriimekLabel.data('prevhtml'));
            }
            $('#form_naslov_cont').before($('#form_ime_priimek_cont'));
        }
    }
    togglePodjetjeFields();

     var ddv_ident = $('.id_ddv', podjetjeFieldSet);
     var is_zavezanec = $('input#zavezanec', podjetjeFieldSet);

     is_zavezanec.change(function(e){
         toggleZavezanec();
     });

    function toggleZavezanec(){
        var checked = is_zavezanec.is(':checked');
        if(checked){
            $('#davcna_st_cont', podjetjeFieldSet).addClass('is_ddv');
        }else{
            $('#davcna_st_cont', podjetjeFieldSet).removeClass('is_ddv');
        }
     }


    $('.registracija-cont .form_toggle').click(function(e){
        e.preventDefault();
        var parent = $(this).parents('.registracija-cont');

        if(parent.hasClass('closed')){
            $('.registracija-cont').each(function(){
                if($(this).hasClass('closed')){
                    $(this).removeClass('closed');
                    $('.registracija_form', this).slideDown();
                }else{
                    $(this).addClass('closed');
                    $('.registracija_form', this).slideUp(function(){

                    });
                }
            });
        }
    });
});