if (typeof(block) == "undefined") {
    var block = {}
}

block.accordion = {
    el: {},
    openedClass: "accordion__item--opened",

    init: function() {
        this.initElements();
        this.initAccordion();
    },

    initElements: function() {
        this.el.accordion = $('.accordion');
    },

    initAccordion: function() {
        var self = this;

        this.el.accordion.find(".accordion__item").each(function(){
            self.setClickHandlers(this);
        });
    },

    itemClickHandler: function() {
        var item = $(this);

        //Removed - not sure was this added
        //$('a', item).on("click", function(e) {
            //e.preventDefault();
        //});
        var content = $('.accordion__item__content', item);
        $(this).addClass(block.accordion.openedClass);

        content.slideDown();

        block.accordion.setClickHandlers(item);
    },

    headerClickHandler: function() {
        var item = $(this).closest(".accordion__item")
        var content = item.find('.accordion__item__content');
        item.removeClass(block.accordion.openedClass);

        content.slideUp(function(){
            block.accordion.setClickHandlers(item);
        });


    },

    setClickHandlers: function(item) {
        item = $(item);

        if (item.hasClass(block.accordion.openedClass)) {
            item.off('click', block.accordion.itemClickHandler);
            $('.accordion__item__header', item).on('click', block.accordion.headerClickHandler);
        } else {
            $('.accordion__item__header', item).off('click', block.accordion.headerClickHandler);
            item.on('click', block.accordion.itemClickHandler);
        }
    }

}

if ($('.accordion').length) {
    block.accordion.init();
}