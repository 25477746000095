;(function( $ , window, document, undefined){
    $.fn.viziloader = function(options){

        var st = {
            //ITODOTRANSLATE
            loading_text: "Stran se nalaga ..."
        }
        $.extend( st, options );

        //var loader_div = $('<div class="viziloader"><div class="middlecont"><div class="loader"></div><div class="loading_text">'+st.loading_text+'</div></div></div>').appendTo('body');
        var loader_div = $('<div class="viziloader"><div class="middlecont"><div class="loading_text">'+st.loading_text+'</div></div></div>').appendTo('body');
    };

})( jQuery, window, document );