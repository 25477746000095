$(function(){

    //Inline qunatity
    $(".inline-quantity").viziNumberField({
        onChange: function(value, field){
            var parent = field.closest('.produkt_sklop');
            //Limit price update only to current row.
            var limiting_parent = field.closest('.produkt_vnos');
            updatePrices(parent, value, limiting_parent);
            $.fn.colorize(false);
        }
    });

    //Update prices
    var updatePricesTimeout;

    function updatePrices(parent, st_kosov, row){
        var row = row || null;
        clearInterval(updatePricesTimeout);
        updatePricesTimeout = setTimeout(function(){
            var val = $(parent).closest('.produkt_sklop').find('h2').text();
        }, 1000);

        if(parent.hasClass('prdktOkovje_narocilo')){
            $('tr.product_data', parent).each(function(){
                var cene = $.parseJSON($('input[name=cene]',this).val());
                var cena = getCena(cene, st_kosov);

                if(st_kosov==0){
                    $('.cena .cena_kos', this).html('--').colorize();
                    $('.cena .st_kosov', this).html(st_kosov + " " + translations.kos);
                    $('.cena .skupaj', this).html('--').colorize();
                }else{
                    $('.cena .cena_kos', this).html( fCena(cena) ).colorize();
                    $('.cena .st_kosov', this).html(st_kosov);
                    $('.cena .skupaj', this).html( fCena(cena * st_kosov  + " " + translations.kos) ).colorize();
                }
            });
        }else{
            var rows;
            if(row){
                rows = row;
            }else{
                rows = $('li.produkt_vnos', parent);
            }

            rows.each(function(){
                var cene = $.parseJSON($('input[name=cene]',this).val());

                var cena = getCena(cene, parseInt(st_kosov));
                //console.log(cene, parseInt(st_kosov), cena);

                if(st_kosov==0){
                    $('.cena_kos strong', this).html('--').colorize();
                    $('.inline-quantity', this).val(st_kosov);
                    $('.cena_skupaj .st_kosov', this).html("--");
                    $('.cena_skupaj strong', this).html('').colorize();
                }else{
                    //$('.cena_kos strong', this).html( fCena(cena) ).colorize();
                    $('.inline-quantity', this).val(st_kosov);
                    $('.cena_skupaj .st_kosov', this).html(st_kosov + "×" + number_format(cena,2,',','.') + '=');
                    $('.cena_skupaj strong', this).html( fCena(cena * st_kosov) ).colorize();
                }
            });
        }
    }

    function getCena(cene, st_kosov){
        var cena;
        for(var key in cene){
            if(key <= st_kosov){
                cena = cene[key];
            }else{
                break;
            }
        }
        return cena;
    }

    //Format price
    window.fCena = function(cena){
        return number_format(cena,2,',','.') + " €";
    }

    window.fStKosov = function(st_kosov){
        if(st_kosov==1){
            return st_kosov + " " + translations.kos;
        }else if(st_kosov==2){
            return st_kosov + " " + translations.kosa;
        }else if(st_kosov in {'3':'','4':''}){
            return st_kosov + " " + translations.kosi;
        }else{
            return st_kosov + " " + translations.kosov;
        }
    }

    //Lightbox - prettyphoto
    $("a[rel^='lightbox']").magnificPopup({
        type: "image",
        image: {
            titleSrc: function(item){
                return item.el.find("img").attr("alt");
            }
        },
        closeOnContentClick: true
    });

});


/*
 **
 ** Rešetke cenik
 **
 */
$(function(){
    $('.tabela_resetke, .product_data').on('keydown','input[name=st_kosov]', function(e){
        if(e.keyCode==38 || e.keyCode==107){
            if($(this).val()==""){
                $(this).val(0);
            }
            if($.isNumeric($(this).val())){
                $(this).val(parseInt($(this).val()) + 1);
            }
        }

        if(e.keyCode==40 || e.keyCode==109){
            if($.isNumeric($(this).val()) && $(this).val() > 0){
                $(this).val(parseInt($(this).val()) - 1);
            }
        }

        var allowed_events = new Array(46,8,9,110,16,36,35);
        if ( ((e.keyCode < 48 || e.keyCode > 57) &&  (e.keyCode < 96 || e.keyCode > 105)) && $.inArray(e.keyCode,allowed_events)==-1) {
            e.preventDefault();
        }
    });

    $('.tabela_resetke').on('keyup','input[name=st_kosov]', function(e){
        if(!$.isNumeric($(this).val())){
            $(this).val("");
        }
        updateCena(this);
    });

    $('.tabela_resetke').on('change','input[name=st_kosov]', function(e){
        if(!$.isNumeric($(this).val())){
            $(this).val("");
        }
        updateCena(this);
    });

    function updateCena(input){
        var row = $(input).closest('li');
        var cena_kos = $('.cena_kos',row).data('cena');
        var st_kosov = $(input).val();

        if(st_kosov==""){
            $('.vrednost', row).html(fCena(0));
            return;
        }

        if(st_kosov<100){
            $('.vrednost', row).html(fCena(cena_kos*st_kosov));
        }else{
            $('.vrednost', row).html("--");
        }

    }
});