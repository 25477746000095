/*
** Prevent javascript errors when console object not present
*/
if(typeof(console) === 'undefined') {
    var console = {}
    console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function() {};
}

/*
**
** Popup init
**
*/

$.extend(true, $.magnificPopup.defaults, {
    tClose: translations.mpClose, //'Zapri (Esc)',
    tLoading: translations.mpLoading, //'Nalagam...',
    gallery: {
        tPrev: translations.mpPrev, //'Prešnja (Leva smerna tipka)',
        tNext: translations.mpNext, //'Naslednja (Desna smerna)',
        tCounter: translations.mpCounter //'%curr% od %total%'
    },
    image: {
        tError: translations.mpImageError //'<a href="%url%">Fotografije</a> ni bilo mogoče naložiti.'
    },
    ajax: {
        tError: translations.mpAjaxError //'<a href="%url%">Poizvedba</a> ni uspela.'
    }
});


/*
**
** Infotip
**
*/
function infoTip(element, cont){

    $(element, cont).tooltip({
        relative: true,
        position: 'bottom right',
        opacity: 1,
        delay: 30,
        lazy: false,
        offset: [-38,0]
    });

}

function initializeTab(cont){
    //Infotip
    infoTip('a.infotip', cont);
}


$(function(){

    infoTip('a.infotip');

    /*
    **
    ** Product tabs
    **
    */
    
    var product_tabs = $('ul.product-tabs');
    // product_tabs.hide();

    product_tabs.each(function(){
        var tab_group = $(this);
        tab_group.after('<div class="js-product-tabs">' +
            '<ul class="js-tabs-labels"></ul>' +
            '<ul class="js-tabs-content"></ul>' +
            '</div>');

        var js_tabs = tab_group.next('div.js-product-tabs');
        var js_labels = $('ul.js-tabs-labels', js_tabs);
        var js_content_conts = $('ul.js-tabs-content', js_tabs);

        $(this).children('li').each(function(index){
            //Make first item active
            var active = (index==0)?' active ':' ';
            var closed = active ? '' : ' closed '
            js_labels.append('<li class="' + active + closed + $(this).attr('class')+'"><a href="#">' + $('a.tab_label', this).html() + '</a><div class="icon"></div><div class="toggle"></div></li>');
            js_content_conts.append('<li class="' + active + ' tab '+$('div.tab_content', this).attr('class')+'">' + $('div.tab_content', this).html() + '</li>');
            $('div.tab_content', this).remove();
        });
    });

    $('.js-product-tabs').on('click', 'ul.js-tabs-labels li', function(e){
        e.preventDefault();
        var index = $(this).index();
        var cont = $(this).parents('div.js-product-tabs');
        var content_ul = cont.find('ul.js-tabs-content');
        var content_labels = cont.find('ul.js-tabs-labels');

        var current_li = content_ul.children('li').eq(index);

        initializeTab(current_li);

        //Check if it's gallery
        if(current_li.hasClass('galerija') && current_li.data('loaded')!=true){
            var produkt_sklop = $(this).parents('.produkt_sklop');
            var product_id = $('.main_product_id', produkt_sklop).val();
            $.get('/galerija/ajax', {id:product_id}, function(data){
                $('.galerija-cont', current_li).html(data);

                $(current_li).magnificPopup({
                    delegate: "a[rel^='lightbox']",
                    type:'image',
                    image: {
                        titleSrc: function(item){
                            return item.el.find("img").attr("alt");
                        }
                    },
                    preloader: true,
                    gallery: {enabled: true},
                    closeOnContentClick: true,
                    callbacks: {
                    }
                });
            });
            current_li.data("loaded", true);
        }

        //Check if it's skica tab
        if(current_li.hasClass('lazy_load') && current_li.data('loaded')!=true){
            $.get($('a', current_li).attr('href'), function(data){
                current_li.html(data);
            });
            current_li.data("loaded", true);
        }


        if(current_li.hasClass('active') && content_ul.is(':visible')){
            // cont.find('ul.js-tabs-labels li').removeClass('active').addClass('closed');
            // content_ul.slideUp();
            // content_labels.removeClass("opened");
        } else {
            content_ul.children('li').removeClass('active');
            current_li.addClass('active');
            cont.find('ul.js-tabs-labels li').removeClass('active').removeClass('closed');
            $(this).addClass('active');
            content_ul.slideDown();
            content_labels.addClass("opened");
        }
    });

    if(product_tabs.hasClass('expanded')){
        $('.js-product-tabs ul.js-tabs-labels li:first-child').trigger("click");
    }

    /*
    **
    ** Search box
    **
    */
    $('div#search_box input.search-txtbox').click(function(e){
        if($(this).hasClass('italic')){
            $(this).val("");
            $(this).removeClass('italic');
        }
    });

    $('div#search_box input.search-txtbox').blur(function(e){
        if($(this).val()==""){
            $(this).val("Iskanje...");
            $(this).addClass('italic');
        }
    });


    /*
    **
    ** Naročilnica scroll
    **
    */
    var sidebar = $('div#stranski-portleti');
    if(sidebar.length > 0){
        var top_offset = 30;
        bottom_offset = $('.footer_wrap').height() + 40;
        var el_top = sidebar.offset().top;

        if(sidebar.length > 0){
            sidebar.data('top', parseInt(sidebar.css("top").substring(0, sidebar.css("top").indexOf("px"))));
            pozicioniraj();
            $(window).scroll(function(){
                pozicioniraj();
            });
        }
        $(window).resize(function(){
            pozicioniraj();
        });
    }

    function pozicioniraj(){
        var scroll_top = $(document).scrollTop();
        var docHeight = $(document).height();

        //Set el_left - temporarily change position to relative to get right offset
        if(sidebar.css('position')!="relative"){
            var old_p = sidebar.css('position');
            var old_l = sidebar.css('left');
            sidebar.css('position', 'relative');
            sidebar.css('left', '0');
            var el_left = sidebar.offset().left;
            sidebar.css('position', old_p);
            sidebar.css('left', old_l);
        }else{
            var el_left = sidebar.offset().left;
        }

        var bottom_offset_in_view =  -docHeight + bottom_offset + scroll_top + $(window).height();

        if( bottom_offset_in_view + top_offset + sidebar.height() > $(window).height() ) {
            sidebar.css('position', 'relative');
            var ttop = docHeight - sidebar.outerHeight() - el_top - bottom_offset;
            sidebar.css('top', ttop);
            sidebar.css('left', 0);
        }else{
            var a = scroll_top;
            var b = el_top - top_offset;

            if(a < b){
                sidebar.css('position', 'relative');
                sidebar.css('top', 0);
                sidebar.css('left',0);
            }else {
                sidebar.css('position', 'fixed');
                sidebar.css('top', top_offset);
                sidebar.css('left', el_left);
            }
        }
    }

    /*
    **
    ** Konfigurator
    **
    */
    $('ul.menu_konfigurator').on("change", 'input[type=checkbox]', function(){
        var selected = new Array();
        var skip_keys = new Array();

        var parent_li = $(this).closest('li');

        var curr_node_name = $('input[name=node_name]',parent_li).val();
        var curr_node_value = $('input[name=node_value]',parent_li).val();
        if(curr_node_value==""){
            $('ul.menu_konfigurator li ul li input[name=node_name]').each(function(){
                var parent_li = $(this).closest('li');
                if($(this).val()==curr_node_name && $('input[name=node_value]', parent_li).val()!=""){
                    $('input[type=checkbox]',parent_li).attr('checked', false);
                }
            });
        }
        if(curr_node_value!=""){
            $('ul.menu_konfigurator li ul li input[name=node_name]').each(function(){
                var parent_li = $(this).closest('li');
                if($(this).val()==curr_node_name && $('input[name=node_value]', parent_li).val()==""){
                    $('input[type=checkbox]',parent_li).attr('checked', false);
                    return false;
                }
            });
        }

        $('ul.menu_konfigurator li ul li').each(function(){
            var input = $('input[type=checkbox]',this);
            if(input.is(':checked')){
                $(this).addClass("active");

                var node_name = $('input[name=node_name]',$(this)).val();

                //Check if current node in array skip_keys and break out each loop
                if($.inArray(node_name, skip_keys)>-1){
                    return false;
                }

                var node_value = $('input[name=node_value]',$(this)).val();
                if(node_value != ""){
                    if(typeof(selected[node_name]) == "undefined"){
                        selected[node_name] = "";
                        selected[node_name] += node_value;
                    }else{
                        selected[node_name] += ".";
                        selected[node_name] += node_value;
                    }
                }else{
                    skip_keys.push(node_name);
                }
            }else{
                $(this).removeClass("active");
            }
        });

        //assemble URL
        var url="?";
        for(var i in selected){
            url += (url=="?")?"":"&";
            url += i + "=" + selected[i];
        }
        $.fn.viziloader();
        window.location.href = url;
    });

    $('.katalog-link').magnificPopup({
        type: 'ajax',

        callbacks: {
            ajaxContentAdded: function() {
                initCatalogOrderForm();
            }
        }
    });
});
