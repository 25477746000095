(function($) {

    $.fn.viziNumberField = function( options ) {

        var settings = $.extend({
            color        : null,
            fontStyle    : null,
            defaultValue : null,
            onChange     : null,
            fieldWidth   : null
        }, options);

        return this.each( function() {

            var inputField = $(this);
            if(settings.defaultValue){
                $(this).val(settings.defaultValue);
            }else if($(this).val()==""){
                $(this).val(0);
            }

            if($(this).data("field-width")){
                $(this).width($(this).data("field-width"));
            }

            $(this).wrap('<div class="vizi-number-field" />');
            $(this).before('<a class="vnf-subtract vnf-inc-btn">&ndash;</a>');
            $(this).after('<a class="vnf-add vnf-inc-btn">+</a>');

            var cont = $(this).closest(".vizi-number-field");

            var btn_sub = $(".vnf-subtract", cont);
            var btn_add = $(".vnf-add", cont);

            btn_sub.on("click", function(e){
                e.preventDefault();
                subtractValue();
            });

            btn_add.on("click", function(e){
                e.preventDefault();
                addValue();
            });

            inputField.on("input", function(){
                notifyAboutChange();
            });

            //Prevent changing of cursor from pointer to caret
            $(".vnf-subtract, .vnf-add", cont).on("mousedown", function(e){
                e.preventDefault();
            });

            inputField.on('keydown', function(e){
                if(e.keyCode==38 || e.keyCode==107){
                    addValue();
                }

                if(e.keyCode==40 || e.keyCode==109){
                    subtractValue();
                }

                var allowed_events = new Array(46,8,9,110,16,36,35);
                if ( ((e.keyCode < 48 || e.keyCode > 57) &&  (e.keyCode < 96 || e.keyCode > 105)) && $.inArray(e.keyCode,allowed_events)==-1) {
                    e.preventDefault();
                }
            });

            inputField.on('keyup', function(e){
                if(!$.isNumeric($(this).val())){
                    $(this).val("");
                }
            });

            function subtractValue(){
                if(!hasNonNumericValues() && inputField.val()>0){
                    inputField.val(parseInt(inputField.val()) - 1);
                    notifyAboutChange();
                }
            }

            function addValue(){
                if(!hasNonNumericValues() && inputField.val()<300){
                    inputField.val(parseInt(inputField.val()) + 1);
                    notifyAboutChange();
                }
            }

            function hasNonNumericValues(){
                if(!$.isNumeric(inputField.val())){
                    inputField.val(0);
                    return true;
                }
            }

            function notifyAboutChange(){
                settings.onChange(inputField.val(), inputField);
            }
        });

    }
}(jQuery));