function setCookie(c_name,value,exminutes, path) {
	var exdate=new Date();
	exdate.setMinutes(exdate.getMinutes() + exminutes);
	var c_value=escape(value) + ((exminutes==null) ? "" : "; expires="+exdate.toUTCString());
	var _path="";
	if(typeof(path)!="undefined"){
		_path="; path="+path;
	}
	document.cookie=c_name + "=" + c_value + _path;
}

function getCookie(c_name) {
	var i,x,y,ARRcookies=document.cookie.split(";");
	for (i=0;i<ARRcookies.length;i++){
		x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
	  	y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
	  	x=x.replace(/^\s+|\s+$/g,"");
	  	if (x==c_name){
	    	return unescape(y);
	    }
	}
}

$.fn.colorize = function(state){
    this.each(function(){
        if(!$.data(this, 'color')){
            $.data(this, 'color', $(this).css('color'));
        }
    });

    if(state==false){
        $('.colorized').each(function(){
            $(this).css('color','#1B7AB6');
            $(this).stop();
            $(this).animate({'color': $.data(this,'color')},1500);
        });
        return;
    }else{
        $(this).each(function(){
            $(this).css('color','#1B7AB6');
            $(this).stop();
            $(this).addClass('colorized');
        });
    }
}

window.number_format = function(number, decimals, dec_point, thousands_sep) {

    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/B(?=(?:d{3})+(?!d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}