require("jquery");
require("./libs/jquery-migrate-1.2.1.min.js");
require("./libs/jquery.cookie.js");
require("./libs/jquery.cookiecuttr.js");
require("./libs/magnificPopup.0.9.3.js");
require("./libs/jquery.tools.min.js");
require("./libs/jquery.color.js");
require("./libs/jquery-ui-1.10.3.custom.min.js");
require("./libs/jquery.viziNumberField.js");
require("./viziens_utils.js");
require("./app.js");
require("./katalog.js");
require("./narocilnica.js");
require("./priceLists.js");
require("./viziloader.js");
require("./accordion-front.js");
require("./registracija.js")

import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

const lightbox = GLightbox({});

import '../css/benkotehna.scss';