$(function(){
    var cont = $('div#narocilnica');
    $(cont).on('click', '.odstrani_link', function(e){
        e.preventDefault();
        var row = $(this).closest('li');
        var row_id = $(row).data('id');
        $.post('/narocilnica/odstrani', {id:row_id}, function(data){
            updateCart(data);
        });
    });

    //Submit form on step1
    $('A.submitStep1').click(function(e){
        e.preventDefault();
        $('#narocilnicaForm').submit();
    });

    //
    //Big list
    //

    //Remove entry
    var big_cont = $('#master_narocilnica');
    $(big_cont).on('click', '.link_odstrani', function(e){
        e.preventDefault();
        var row = $(this).closest('tr');
        var row_id = $(row).data('id');

        $.post('/narocilnica/odstrani-ajax', {id:row_id}, function(data){
            big_cont.html(data);
        });
    });
    $(big_cont).on('click', '.osvezi', function(e){
        e.preventDefault();
        var row = $(this).closest('tr');
        var row_id = $(row).data('id');
        var st_kosov_input = $('input[name=st_kosov]', row);
        var st_kosov = st_kosov_input.val();

        $.post('/narocilnica/st-kosov', {id:row_id, st_kosov:st_kosov}, function(data){
            big_cont.html(data);
        });
    });


    //Add products to cart
    $('.tabela_perforirana, .tabela_plocevinaste, .tabela_gfk, .tabela_kleme,  .tabela_mreze, ' +
        '.tabela_lestveni_profil, .tabela_ekspandirana, .tabela_vpenjalni').
    on('click', '.dodaj_narocilo_link', function(e){
        e.preventDefault();

        var row = $(this).closest('li');
        var product_id = $('input[name=product_id]', row).val();
        var top_cont = $(this).closest('li.tab');

        var quantity = $(".inline-quantity", row).val();
        addToCart(product_id, quantity);
    });

    $('.tabela_okovje').on('click','.dodaj_narocilo_link', function(e){
        e.preventDefault();

        var row = $(this).closest('tr');
        var product_id = $('input[name=product_id]', row).val();
        var top_cont = $(this).closest('li.tab');

        addToCart(product_id, st_kosov);
    });

    $('.tabela_resetke, .tabela_okrogleResetke').on('click', '.dodaj_narocilo_link', function(e){
        e.preventDefault();
        var row = $(this).closest('li');
        var product_id = $('input[name=product_id]', row).val();
        var st_kosov = $('input[name=st_kosov]', row).val();
        //$('input[name=st_kosov]', row).val("");
        addToCart(product_id, st_kosov);
    });

    $('.product_data').on('click', '.naroci-link', function(e){
        e.preventDefault();
        var product_id = $('input[name=product_id]', e.delegateTarget).val();
        var st_kosov = $('input[name=st_kosov]', e.delegateTarget).val();
        addToCart(product_id, st_kosov);
    });

    $('.productItem3InRow').on('click','.orderLink', function(e){
        e.preventDefault();
        var product_id = $('input[name=product_id]', e.delegateTarget).val();
        var st_kosov = $('input[name=st_kosov]', e.delegateTarget).val();
        addToCart(product_id, st_kosov);
    });

    window.addToCart = function(product_id, st_kosov){
        $.post('/narocilnica/dodaj', {product_id:product_id, st_kosov:st_kosov}, function(data){
            updateCart(data);
        });
        if(st_kosov==0){
            alert(translations.izberite_st_kosov + "!");
        }
    }

    window.updateCart = function(data){
        $('#narocilnica_list').html(data);
    }

});